<template>
  <div>
    <vuetify-audio
      v-if="element_type == 'Audio'"
      :file="element_data"
      color="success"
    ></vuetify-audio>
    <v-img
      style="max-width: 60%; max-height: 450px"
      v-if="element_type == 'Image'"
      :src="element_data"
      contain
    ></v-img>
    <div v-if="element_type == 'Video'" class="videoUpload">
      <!--      vimeo-->
      <b-embed type="iframe" aspect="16by9" :src="element_data"></b-embed>

      <!--      muse-->
      <!--      <b-embed-->
      <!--          type="iframe"-->
      <!--          aspect="16by9"-->
      <!--          :src="'https://muse.ai/embed/'+ element_data+'?'"-->
      <!--      ></b-embed>-->
    </div>
    <div>
      <Editor
        v-if="element_type == 'Post'"
        :value="element_data"
        disabled
      ></Editor>
    </div>
    <template v-if="element_type == 'Album'">
      <v-row v-if="element_data.length">
        <template v-for="(photo, index) in element_data">
          <v-col cols="3" :key="index">
            <v-img contain :src="photo.url"></v-img>
          </v-col>
        </template>
      </v-row>
    </template>
    <div v-if="element_type === 'Pdf'" class="videoUpload">
      <b-embed
        v-if="element_data !== undefined"
        type="iframe"
        aspect="16by9"
        :src="element_data"
      ></b-embed>
    </div>
    <div v-if="element_type === 'Embed_yt'" class="videoUpload">
      <b-embed
        v-if="element_data !== undefined"
        type="iframe"
        aspect="16by9"
        :src="'https://www.youtube.com/embed/' + convertUrlToIdYt(element_data)"
      ></b-embed>
    </div>
  </div>
</template>

<script>
import { getIdFromUrl } from "vue-youtube";

export default {
  name: "ElementType",
  components: {
    VuetifyAudio: () => import("vuetify-audio"),
    Editor: () => import("@/components/ckeditor5/Editor.vue"),
  },
  props: ["element_type", "element_data"],
  data() {
    return {};
  },
  methods: {
    convertUrlToIdYt(url) {
      let id = getIdFromUrl(url);
      return id;
    },
  },
};
</script>

<style lang="css" scoped>
.videoUpload {
  max-width: 60%;
}
</style>
